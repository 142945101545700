import {Inject, Injectable} from "vue-typedi";
import {Action, Getter, Mutation, State} from "vuex-simple";
import DictMedicalCareService from "@/service/dict/dict-medical-care.service";
import tokens from "@/service/tokens";
import {CreateDictMedicalCare, DictMedicalCare, UpdateDictMedicalCare} from "@/@type/dict-medicalcare";

@Injectable()
export class Medical {

  @Inject(tokens.MEDICAL_CARE_SERVICE)
  private dictMedicalCareService: DictMedicalCareService;

  @State()
  private items: DictMedicalCare[] = [];

  @Mutation()
  public mutate(nb: DictMedicalCare[]) {
    this.items = nb;
  }

  @Mutation()
  public mutateAdd(nb: DictMedicalCare) {
    this.items.push(nb);
  }

  @Action()
  public async create(data: CreateDictMedicalCare) {
    const uid = await this.dictMedicalCareService.create(data);
    if(uid)
      await this.load();
  }

  @Action()
  public async update(data: UpdateDictMedicalCare) {
    const uid = await this.dictMedicalCareService.update(data);
    if(uid)
      await this.load();
  }

  @Action()
  public async delete(item: DictMedicalCare) {
    const res = await this.dictMedicalCareService.delete(item.uid);
    if(res)
      item.deleted = true
  }

  @Action()
  public async restore(item: DictMedicalCare) {
    const res = await this.dictMedicalCareService.restore(item.uid);
    if(res)
      item.deleted = false
  }

  @Action()
  public async load(): Promise<any>{
    console.log("load: medical care")
    const items = await this.dictMedicalCareService.getList()
    this.mutate(items)
  }

  @Getter()
  public get getList():  DictMedicalCare[]{
    return this.items.sort((a, b) => Number(a.deleted) - Number(b.deleted))
  }

}
