import RefApi from "@/service/api/ref/ref.api";
import {RefType} from "@/@type/ref.type";
import {Service} from "vue-typedi";
import tokens from "@/service/tokens";

const api =  new RefApi();

export default interface RefService {

  getList(): Promise<RefType[]>;
}

@Service(tokens.REF_SERVICE)
export default class RefServiceImpl implements RefService {


  async getList(): Promise<RefType[]> {
    return await api.getList();
  }
}
