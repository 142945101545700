import { Module, State } from 'vuex-simple';
//example
import { BarModule } from './modules/example/bar';

import { App } from './modules/app';
import { Catalog } from './modules/catalog';
import { Users } from "@/store/modules/src/users";
import {Cashes} from "@/store/modules/src/cashes";
import {Organization} from "@/store/modules/src/organization";
import {BaseModule} from "@/store/modules/src/base.module";
import {Offer} from "@/store/modules/src/offer";
import {CashUser} from "@/store/modules/src/cashUser";
import {ReportsCache} from "@/store/modules/src/reports.cache";
import {Company} from "@/store/modules/src/company";
import {Direction} from "@/store/modules/src/direction";
import {Medical} from "@/store/modules/src/medical";
import {RefModule} from "@/store/modules/src/ref.module";

export class MyStore {

    @Module()
    public bar = new BarModule();

    @Module()
    public app = new App();

    @Module()
    public catalog = new Catalog();

    @Module()
    public users = new Users();

    @Module()
    public cashes = new Cashes();

    @Module()
    public organization = new Organization();

    @Module()
    public base = new BaseModule();

    @Module()
    public offer = new Offer();

    @Module()
    public cashUser = new CashUser();

    @Module()
    public reportSettings = new ReportsCache();

    @Module()
    public company = new Company();

    @Module()
    public direction = new Direction();

    @Module()
    public medical = new Medical();

    @Module()
    public ref = new RefModule();

    @State()
    public version = "1.0.0";
}
