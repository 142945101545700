import {Inject, Injectable} from "vue-typedi";
import RefService from "@/service/ref/ref.service";
import tokens from "@/service/tokens";
import {Action, Getter, Mutation, State} from "vuex-simple";
import {RefType, RefValueType} from "@/@type/ref.type";

@Injectable()
export class RefModule {

  @Inject(tokens.REF_SERVICE)
  private refService: RefService;

  @State()
  private items: RefType[] = [];

  @Mutation()
  public mutate(nb: RefType[]) {
    this.items = nb;
  }

  @Action()
  public async load(): Promise<any>{
    console.log("load: ref")
    const items = await this.refService.getList()
    this.mutate(items)
  }

  @Getter()
  public get getValues(): (codeName: string) => RefValueType[] {
    return (codeName: string) => {
      const ref = this.items?.find(r => r.codeName === codeName);
      return ref ? ref.values : [];
    };
  }

}
