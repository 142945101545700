import {Action, Getter, Mutation, State} from 'vuex-simple';
import catalog from "@/utils/clientSession/catalog";
import catalogs from "@/utils/clientSession/catalogs.";
import Vue from "vue";
import cookie from "@/utils/clientSession/cookie";

export class App {
  @State()
  public inited: boolean;
  @State()
  public error: any;


  constructor(b: boolean = false) {
    this.inited = b;
    this.error = {show: false, type: null, message: null };
  }

  @Mutation()
  public async mutateInitedTrue() {
    setTimeout(() => {
      this.inited = true
    }, 2000)
  }

  @Mutation()
  public async mutateError(error: any) {
    this.error = error
  }

  public async init() {
      return new Promise ((resolve : any) => {
          catalog().then((catalog_) => {
              if (catalog_ && this.inited) return resolve()
              console.log("ctx: loaded")
              this.initCatalogs()
              this.initUsers()
              this.initCashes()
              this.initOrganization()
              this.initOffer()
              this.initCashUser()
              this.mutateInitedTrue();
              this.initReportSettings();
              this.initCompany();
              this.initDirection();
              this.initMedical();
              this.initRefs();
              resolve()
              console.log("ctx: initialized")
          })
      })
  }

  initCatalogs(){
    const catalogs_ = catalogs()
    if(catalogs_){
      Vue.prototype.$myStore.catalog.setCatalogs(catalogs_)

      if(cookie.get('sysname'))
        for (const catalog_ of catalogs_){
          if(catalog_.catalog === cookie.get('sysname'))
            Vue.prototype.$myStore.catalog.setCurrentCatalog(catalog_)
        }

      if(!cookie.get('catalog'))
        Vue.prototype.$myStore.catalog.setCurrentCatalog(catalogs_[0])
      else
        Vue.prototype.$myStore.catalog.setCurrentCatalog(JSON.parse(cookie.get('catalog')))
    }
  }

  initUsers(){
      Vue.prototype.$myStore.users.setUsers()
  }

  initCashes(){
      Vue.prototype.$myStore.cashes.load()
  }

  initOrganization(){
      Vue.prototype.$myStore.organization.load()
  }

  initOffer(){
      Vue.prototype.$myStore.offer.load()
  }

  initCashUser(){
    Vue.prototype.$myStore.cashUser.load()
  }

  initReportSettings(){
    Vue.prototype.$myStore.reportSettings.load();
  }

  initCompany(){
    Vue.prototype.$myStore.company.load();
  }

  initDirection(){
    Vue.prototype.$myStore.direction.load();
  }

  initMedical() {
    Vue.prototype.$myStore.medical.load();
  }

  initRefs() {
    Vue.prototype.$myStore.ref.load()
  }

  @Action()
  setError(error: any){
    this.mutateError(error)
  }



  @Getter()
  public get getError() {
    return this.error
  }
}

// timeout(ms: number) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }
